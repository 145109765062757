import { Grid } from "@mui/material";
import { IMessage } from "@stomp/stompjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  AbstractMonitorMessage,
  AbstractStatusPayload,
  AbstractStatusPayloadV0V1,
  DeviceConfiguration,
  MonitorPacketDto,
  PlatformSensorV2,
  StatusPayloadV2,
} from "../../../cobra-backend-client";
import {
  DEVICE_STATUS_INDEX,
  STATUS_MONITOR_VERSION_0,
  STATUS_MONITOR_VERSION_1,
  STATUS_MONITOR_VERSION_2,
} from "../../../constants/common";
import { TOPIC_MONITORING } from "../../../constants/topics";
import { useStompSubscription } from "../../../hooks/useStompSubscription";
import { RootState } from "../../../stores/store";
import { AngleCalibration } from "./AngleCalibration";
import { AngularRate } from "./AngularRate";
import { DeviceRestart } from "./DeviceRestart";

interface Props {
  deviceConfiguration: DeviceConfiguration;
}

const ControllerInstallationContainer = (props: Props) => {
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const [updatedDeviceConfiguration, setUpdatedDeiceConfiguration] =
    useState<DeviceConfiguration>(props.deviceConfiguration);
  const [showAngleCalibrationProgress, setShowAngleCalibrationProgress] =
    useState(false);
  const [platformAngle, setPlatformAngle] = useState<number>();
  const [platformSensorV2, setPlatformSensorV2] = useState<PlatformSensorV2>();

  const onMonitorMessage = (message: IMessage) => {
    const monitorPacket = JSON.parse(message.body) as MonitorPacketDto;

    if (
      currentConnectedDeviceId == monitorPacket.deviceId &&
      monitorPacket.payloads &&
      monitorPacket.payloads.length > 0
    ) {
      const monitorMessageDto: AbstractMonitorMessage | undefined =
        monitorPacket.payloads.find(
          (monitorMessage: AbstractMonitorMessage) =>
            monitorMessage.index === DEVICE_STATUS_INDEX
        );
      if (!monitorMessageDto) return;
      if (monitorMessageDto.index === DEVICE_STATUS_INDEX) {
        const abstractStatusPayload =
          monitorMessageDto.payload as AbstractStatusPayload;
        switch (abstractStatusPayload.version) {
          case STATUS_MONITOR_VERSION_1:
          case STATUS_MONITOR_VERSION_0:
            const abstractStatusPayloadV0V1 =
              monitorMessageDto.payload as AbstractStatusPayloadV0V1;
            setPlatformAngle(abstractStatusPayloadV0V1.platformAngle);
            break;

          case STATUS_MONITOR_VERSION_2:
            const statusPayloadV2 =
              monitorMessageDto.payload as StatusPayloadV2;
            setPlatformAngle(statusPayloadV2.platformSensor?.platformAngle);
            setPlatformSensorV2(statusPayloadV2.platformSensor);
            break;
        }
      }
    }
  };

  useStompSubscription(TOPIC_MONITORING, onMonitorMessage);

  return (
    <>
      {!!currentConnectedDeviceId && (
        <Grid container spacing={2} flexGrow={1} rowSpacing={1}>
          <Grid item xs={12}>
            <AngleCalibration
              deviceId={currentConnectedDeviceId}
              updatedDeviceConfiguration={updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
              showAngleCalibrationProgress={showAngleCalibrationProgress}
              setShowAngleCalibrationProgress={setShowAngleCalibrationProgress}
              platformAngle={platformAngle}
            />
          </Grid>
          <Grid item xs={12}>
            <AngularRate platformSensorV2={platformSensorV2} />
          </Grid>
          <Grid item xs={12}>
            <DeviceRestart
              deviceId={currentConnectedDeviceId}
              showAngleCalibrationProgress={showAngleCalibrationProgress}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { ControllerInstallationContainer };
