import { useEffect, useState } from "react";
import {
  PlatformSensorV0V1,
  PlatformSensorV2,
} from "../../../cobra-backend-client";
import { STATUS_MONITOR_VERSION_2 } from "../../../constants/common";
import { Chip, Grid, Typography } from "@mui/material";
import {
  PlatformSensorWarningEnum,
  PlatformSensorWarningColor,
  PlatformSensorWarningMessage,
} from "../../../models/dto/ws/monitoring/PlatformSensorWarningEnum";

interface Props {
  platformSensor: PlatformSensorV0V1 | PlatformSensorV2 | undefined;
  statusMonitorPayloadVersion: string | undefined;
}

const PlatformsensorWarningComponent = (props: Props) => {
  const [platformSensorV2, setPlatformSensorV2] = useState<PlatformSensorV2>();
  useEffect(() => {
    if (props.statusMonitorPayloadVersion === STATUS_MONITOR_VERSION_2) {
      setPlatformSensorV2(props.platformSensor as PlatformSensorV2);
    } else {
      setPlatformSensorV2(undefined);
    }
  }, [props.platformSensor, props.statusMonitorPayloadVersion]);

  return (
    <>
      {props.statusMonitorPayloadVersion &&
        props.platformSensor &&
        platformSensorV2 &&
        platformSensorV2.state && (
          <>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              <Typography>Warning </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Chip
                label={
                  PlatformSensorWarningMessage[
                    platformSensorV2.state as PlatformSensorWarningEnum
                  ] ?? "Unknown"
                }
                variant="outlined"
                color={
                  PlatformSensorWarningColor[
                    platformSensorV2.state as PlatformSensorWarningEnum
                  ] ?? "default"
                }
              />
            </Grid>
          </>
        )}
    </>
  );
};

export { PlatformsensorWarningComponent };
