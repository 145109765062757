import { IMessage } from "@stomp/stompjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { COBRA_API } from "../../api/config";
import { HandheldConnectionDto } from "../../cobra-backend-client";
import { TOPIC_HANDHELD_CONNECTION } from "../../constants/topics";
import { useDeactivateOpenHandheldConnection } from "../../hooks/useDeactivateOpenHandheldConnection";
import { useIsDeviceOnline } from "../../hooks/useIsDeviceOnline";
import { useIsDeviceRestartScheduled } from "../../hooks/useIsDeviceRestartScheduled";
import { useStompSubscription } from "../../hooks/useStompSubscription";
import {
  setIsDeviceStatusMonitorStarted,
  setIsHandheldConnectionActive,
} from "../../stores/slices/deviceSlice";
import { setVariokeys } from "../../stores/slices/variokeysSlice";
import { RootState } from "../../stores/store";
import { DeviceOfflineDialog } from "../common/DeviceOfflineDialog";

interface Props {}

const DISCONNECTION_REASON_LOST_CONNECTION_TEXT =
  "The device has lost connection. You can wait for it to reconnect or choose to disconnect from the device.";

const DISCONNECTION_REASON_REBOOT_TEXT = `The device is currently undergoing a planned reboot, which is a necessary step to apply updates. This process may take a few moments to complete. You can either wait for the device to reconnect automatically or choose to disconnect and reconnect at a later time.`;

const ConnectedDeviceWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const isDeviceOnline = useIsDeviceOnline();
  const isDeviceRestartScheduled = useIsDeviceRestartScheduled();

  useDeactivateOpenHandheldConnection();

  useEffect(() => {
    getVariokeys();
  }, []);

  const getVariokeys = async () => {
    try {
      const variokeys = (await COBRA_API.DeviceConfiguration.getVariokeyList())
        .data;
      dispatch(setVariokeys(variokeys.variokeys ?? []));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isDeviceOnline) {
      dispatch(setIsDeviceStatusMonitorStarted(false));
    }
  }, [isDeviceOnline]);

  const onHandhledIsOpenConnectionActiveMessage = (message: IMessage) => {
    const handheldConnectionDto = JSON.parse(
      message.body
    ) as HandheldConnectionDto;

    if (currentConnectedDeviceId === handheldConnectionDto.deviceId) {
      dispatch(setIsHandheldConnectionActive(!!handheldConnectionDto.active));
    }
  };

  useStompSubscription(
    TOPIC_HANDHELD_CONNECTION,
    onHandhledIsOpenConnectionActiveMessage
  );

  return (
    <>
      {!isDeviceOnline && !!currentConnectedDeviceId && (
        <DeviceOfflineDialog
          disconnectionReasonText={DISCONNECTION_REASON_LOST_CONNECTION_TEXT}
        />
      )}
      {isDeviceOnline && isDeviceRestartScheduled && (
        <DeviceOfflineDialog
          disconnectionReasonText={DISCONNECTION_REASON_REBOOT_TEXT}
        />
      )}
      <Outlet />
    </>
  );
};

export { ConnectedDeviceWrapper };
