/* tslint:disable */
/* eslint-disable */
/**
 * Cobra Backend
 * Cobra Backend
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: Assen.Sharlandjiev@akros.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AbstractActivatedSensors
 */
export interface AbstractActivatedSensors {
    /**
     * 
     * @type {boolean}
     * @memberof AbstractActivatedSensors
     */
    'plSensor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractActivatedSensors
     */
    'autotiltDownSwitch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractActivatedSensors
     */
    'cabinSwitch'?: boolean;
}
/**
 * 
 * @export
 * @interface AbstractConfiguration
 */
export interface AbstractConfiguration {
    /**
     * 
     * @type {string}
     * @memberof AbstractConfiguration
     */
    'version': string;
    /**
     * 
     * @type {number}
     * @memberof AbstractConfiguration
     */
    'crc': number;
}
/**
 * 
 * @export
 * @interface AbstractMonitorMessage
 */
export interface AbstractMonitorMessage {
    /**
     * 
     * @type {number}
     * @memberof AbstractMonitorMessage
     */
    'index'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractMonitorMessage
     */
    'length'?: number;
    /**
     * 
     * @type {object}
     * @memberof AbstractMonitorMessage
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface AbstractStatusPayload
 */
export interface AbstractStatusPayload {
    /**
     * 
     * @type {string}
     * @memberof AbstractStatusPayload
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface AbstractStatusPayloadV0V1
 */
export interface AbstractStatusPayloadV0V1 {
    /**
     * 
     * @type {string}
     * @memberof AbstractStatusPayloadV0V1
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'vehicleBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'platformAngle'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'errorCode'?: number;
    /**
     * 
     * @type {HandheldLimitation}
     * @memberof AbstractStatusPayloadV0V1
     */
    'handheldLimitation'?: HandheldLimitation;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'hhZone'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof AbstractStatusPayloadV0V1
     */
    'hhButtons'?: Array<boolean>;
    /**
     * 
     * @type {OutputA}
     * @memberof AbstractStatusPayloadV0V1
     */
    'outputA'?: OutputA;
    /**
     * 
     * @type {OutputB}
     * @memberof AbstractStatusPayloadV0V1
     */
    'outputB'?: OutputB;
    /**
     * 
     * @type {ActivatedSensorsV0V1}
     * @memberof AbstractStatusPayloadV0V1
     */
    'activatedSensors'?: ActivatedSensorsV0V1;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'hhSignal'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractStatusPayloadV0V1
     */
    'wlanIndex'?: number;
    /**
     * 
     * @type {MonitorAutoTiltDown}
     * @memberof AbstractStatusPayloadV0V1
     */
    'autoTiltDown'?: MonitorAutoTiltDown;
    /**
     * 
     * @type {CabinSwitch}
     * @memberof AbstractStatusPayloadV0V1
     */
    'cabinSwitch'?: CabinSwitch;
}
/**
 * 
 * @export
 * @interface ActivatedSensorsV0V1
 */
export interface ActivatedSensorsV0V1 {
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV0V1
     */
    'plSensor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV0V1
     */
    'autotiltDownSwitch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV0V1
     */
    'cabinSwitch'?: boolean;
}
/**
 * 
 * @export
 * @interface ActivatedSensorsV2
 */
export interface ActivatedSensorsV2 {
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV2
     */
    'plSensor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV2
     */
    'autotiltDownSwitch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV2
     */
    'cabinSwitch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivatedSensorsV2
     */
    'inputInterface'?: boolean;
}
/**
 * 
 * @export
 * @interface Adc
 */
export interface Adc {
    /**
     * 
     * @type {number}
     * @memberof Adc
     */
    'pwrFailThresholdToOk': number;
    /**
     * 
     * @type {number}
     * @memberof Adc
     */
    'pwrFailThresholdToNok': number;
    /**
     * 
     * @type {number}
     * @memberof Adc
     */
    'psensRange': number;
    /**
     * 
     * @type {number}
     * @memberof Adc
     */
    'psensShunt': number;
}
/**
 * 
 * @export
 * @interface AngleSector
 */
export interface AngleSector {
    /**
     * 
     * @type {Array<number>}
     * @memberof AngleSector
     */
    'minDeg': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AngleSector
     */
    'maxDeg': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AngleSector
     */
    'angleLimitNeg': number;
    /**
     * 
     * @type {number}
     * @memberof AngleSector
     */
    'angleLimitPos': number;
    /**
     * 
     * @type {number}
     * @memberof AngleSector
     */
    'angleStopPoint': number;
    /**
     * 
     * @type {number}
     * @memberof AngleSector
     */
    'angleBackZoneFastCloseMin': number;
    /**
     * 
     * @type {number}
     * @memberof AngleSector
     */
    'angleBackZoneFastCloseMax': number;
}
/**
 * 
 * @export
 * @interface ApplicationMetadata
 */
export interface ApplicationMetadata {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetadata
     */
    'deviceConfigurationFileCreatedOn'?: string;
}
/**
 * 
 * @export
 * @interface AutomaticTilt
 */
export interface AutomaticTilt {
    /**
     * 
     * @type {Down}
     * @memberof AutomaticTilt
     */
    'down': Down;
    /**
     * 
     * @type {Up}
     * @memberof AutomaticTilt
     */
    'up': Up;
}
/**
 * 
 * @export
 * @interface Back
 */
export interface Back {
    /**
     * 
     * @type {number}
     * @memberof Back
     */
    'meanMin': number;
    /**
     * 
     * @type {number}
     * @memberof Back
     */
    'meanMax': number;
    /**
     * 
     * @type {number}
     * @memberof Back
     */
    'delta': number;
    /**
     * 
     * @type {number}
     * @memberof Back
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface CabinSwitch
 */
export interface CabinSwitch {
    /**
     * 
     * @type {boolean}
     * @memberof CabinSwitch
     */
    'inSwitch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CabinSwitch
     */
    'outLed'?: boolean;
}
/**
 * 
 * @export
 * @interface CableControl
 */
export interface CableControl {
    /**
     * 
     * @type {number}
     * @memberof CableControl
     */
    'footSwitchMode': number;
    /**
     * 
     * @type {number}
     * @memberof CableControl
     */
    'cableControlMode': number;
    /**
     * 
     * @type {boolean}
     * @memberof CableControl
     */
    'cableControlEn2H': boolean;
}
/**
 * 
 * @export
 * @interface CompositeVersion
 */
export interface CompositeVersion {
    /**
     * 
     * @type {string}
     * @memberof CompositeVersion
     */
    'major'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompositeVersion
     */
    'minor'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompositeVersion
     */
    'fix'?: string;
}
/**
 * 
 * @export
 * @interface CurrentLimit
 */
export interface CurrentLimit {
    /**
     * 
     * @type {number}
     * @memberof CurrentLimit
     */
    'istatic'?: number;
}
/**
 * 
 * @export
 * @interface Danger
 */
export interface Danger {
    /**
     * 
     * @type {number}
     * @memberof Danger
     */
    'offsetToSideZone': number;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'deviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'timestamp'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'online'?: boolean;
    /**
     * 
     * @type {RxPlsVersion}
     * @memberof Device
     */
    'version'?: RxPlsVersion;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'restartScheduled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'platformSensorPresent'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceConfiguration
 */
export interface DeviceConfiguration {
    /**
     * 
     * @type {string}
     * @memberof DeviceConfiguration
     */
    'version': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceConfiguration
     */
    'crc': number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceConfiguration
     */
    'configMissing': boolean;
    /**
     * 
     * @type {Output}
     * @memberof DeviceConfiguration
     */
    'out': Output;
    /**
     * 
     * @type {OutputMask}
     * @memberof DeviceConfiguration
     */
    'outMask': OutputMask;
    /**
     * 
     * @type {FunctionMask}
     * @memberof DeviceConfiguration
     */
    'functionMask': FunctionMask;
    /**
     * 
     * @type {CableControl}
     * @memberof DeviceConfiguration
     */
    'cableControl': CableControl;
    /**
     * 
     * @type {CurrentLimit}
     * @memberof DeviceConfiguration
     */
    'currentLimit': CurrentLimit;
    /**
     * 
     * @type {IoMonitorConfig}
     * @memberof DeviceConfiguration
     */
    'ioMonitor': IoMonitorConfig;
    /**
     * 
     * @type {Imu}
     * @memberof DeviceConfiguration
     */
    'imu': Imu;
    /**
     * 
     * @type {AngleSector}
     * @memberof DeviceConfiguration
     */
    'angleSector': AngleSector;
    /**
     * 
     * @type {Vred}
     * @memberof DeviceConfiguration
     */
    'vred': Vred;
    /**
     * 
     * @type {Pfr}
     * @memberof DeviceConfiguration
     */
    'pfr': Pfr;
    /**
     * 
     * @type {Powerdown}
     * @memberof DeviceConfiguration
     */
    'powerdown': Powerdown;
    /**
     * 
     * @type {Adc}
     * @memberof DeviceConfiguration
     */
    'adc': Adc;
    /**
     * 
     * @type {number}
     * @memberof DeviceConfiguration
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof DeviceConfiguration
     */
    'varioKey': number;
    /**
     * 
     * @type {LoginOption}
     * @memberof DeviceConfiguration
     */
    'loginOption': LoginOption;
    /**
     * 
     * @type {Logger}
     * @memberof DeviceConfiguration
     */
    'logger': Logger;
    /**
     * 
     * @type {RssiZone}
     * @memberof DeviceConfiguration
     */
    'rssiZone': RssiZone;
    /**
     * 
     * @type {AutomaticTilt}
     * @memberof DeviceConfiguration
     */
    'automaticTilt': AutomaticTilt;
    /**
     * 
     * @type {Psio}
     * @memberof DeviceConfiguration
     */
    'psio': Psio;
    /**
     * 
     * @type {Pc}
     * @memberof DeviceConfiguration
     */
    'pc': Pc;
    /**
     * 
     * @type {TerminalDebug}
     * @memberof DeviceConfiguration
     */
    'terminalDebug': TerminalDebug;
    /**
     * 
     * @type {ResForDev}
     * @memberof DeviceConfiguration
     */
    'resForDev': ResForDev;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviceConfiguration
     */
    'reservedTest': Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceConfigurationMetaData
 */
export interface DeviceConfigurationMetaData {
    /**
     * 
     * @type {DeviceMetadata}
     * @memberof DeviceConfigurationMetaData
     */
    'deviceMetada'?: DeviceMetadata;
    /**
     * 
     * @type {UserMetada}
     * @memberof DeviceConfigurationMetaData
     */
    'userMetadata'?: UserMetada;
    /**
     * 
     * @type {ApplicationMetadata}
     * @memberof DeviceConfigurationMetaData
     */
    'applicationMetadata'?: ApplicationMetadata;
}
/**
 * 
 * @export
 * @interface DeviceConfigurationUpdateDto
 */
export interface DeviceConfigurationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceConfigurationUpdateDto
     */
    'deviceConfigurationUpdateResponse'?: DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum;
}

export const DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum = {
    ErrIncorrectChecksum: 'CONF_ERR_INCORRECT_CHECKSUM',
    ErrIncorrectVersion: 'CONF_ERR_INCORRECT_VERSION',
    ErrIncorrectLength: 'CONF_ERR_INCORRECT_LENGTH',
    ErrUnknown: 'CONF_ERR_UNKNOWN',
    NoErr: 'CONF_NO_ERR'
} as const;

export type DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum = typeof DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum[keyof typeof DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum];

/**
 * 
 * @export
 * @interface DeviceMetadata
 */
export interface DeviceMetadata {
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadata
     */
    'deviceType'?: string;
    /**
     * 
     * @type {DeviceVersion}
     * @memberof DeviceMetadata
     */
    'controllerVersions'?: DeviceVersion;
    /**
     * 
     * @type {DeviceVersion}
     * @memberof DeviceMetadata
     */
    'platformSensorVersions'?: DeviceVersion;
}
/**
 * 
 * @export
 * @interface DeviceTransmitterMemoryConfiguration
 */
export interface DeviceTransmitterMemoryConfiguration {
    /**
     * 
     * @type {string}
     * @memberof DeviceTransmitterMemoryConfiguration
     */
    'version': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceTransmitterMemoryConfiguration
     */
    'crc': number;
    /**
     * 
     * @type {SerHs}
     * @memberof DeviceTransmitterMemoryConfiguration
     */
    'serHs': SerHs;
    /**
     * 
     * @type {number}
     * @memberof DeviceTransmitterMemoryConfiguration
     */
    'serHsApproved': number;
}
/**
 * 
 * @export
 * @interface DeviceVersion
 */
export interface DeviceVersion {
    /**
     * 
     * @type {number}
     * @memberof DeviceVersion
     */
    'serial'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'hw'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'mcBl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'mcFw'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'mcCanProt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'scBl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'scFw'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersion
     */
    'scCanProt'?: string;
}
/**
 * 
 * @export
 * @interface Down
 */
export interface Down {
    /**
     * 
     * @type {boolean}
     * @memberof Down
     */
    'enable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Down
     */
    'useAnalogPressureSens': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Down
     */
    'invertDigitalPressureSens': boolean;
    /**
     * 
     * @type {string}
     * @memberof Down
     */
    'reserve': string;
    /**
     * 
     * @type {number}
     * @memberof Down
     */
    'thresholdAnalogPressure': number;
}
/**
 * 
 * @export
 * @interface FirmwareDeviceMicroControllerVersionDto
 */
export interface FirmwareDeviceMicroControllerVersionDto {
    /**
     * 
     * @type {string}
     * @memberof FirmwareDeviceMicroControllerVersionDto
     */
    'deviceMicroController'?: FirmwareDeviceMicroControllerVersionDtoDeviceMicroControllerEnum;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDeviceMicroControllerVersionDto
     */
    'firmwareVersion'?: string;
}

export const FirmwareDeviceMicroControllerVersionDtoDeviceMicroControllerEnum = {
    DeviceMc: 'COBRA_DEVICE_MC',
    DeviceSc: 'COBRA_DEVICE_SC',
    PlSensorMc: 'COBRA_PL_SENSOR_MC',
    PlSensorSc: 'COBRA_PL_SENSOR_SC'
} as const;

export type FirmwareDeviceMicroControllerVersionDtoDeviceMicroControllerEnum = typeof FirmwareDeviceMicroControllerVersionDtoDeviceMicroControllerEnum[keyof typeof FirmwareDeviceMicroControllerVersionDtoDeviceMicroControllerEnum];

/**
 * 
 * @export
 * @interface FunctionMask
 */
export interface FunctionMask {
    /**
     * 
     * @type {number}
     * @memberof FunctionMask
     */
    'open': number;
    /**
     * 
     * @type {number}
     * @memberof FunctionMask
     */
    'close': number;
    /**
     * 
     * @type {number}
     * @memberof FunctionMask
     */
    'up': number;
    /**
     * 
     * @type {number}
     * @memberof FunctionMask
     */
    'down': number;
}
/**
 * 
 * @export
 * @interface Handheld
 */
export interface Handheld {
    /**
     * 
     * @type {number}
     * @memberof Handheld
     */
    'connectionInfo'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Handheld
     */
    'reserve'?: Array<number>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof Handheld
     */
    'buttons'?: Array<boolean>;
    /**
     * 
     * @type {number}
     * @memberof Handheld
     */
    'zone'?: number;
    /**
     * 
     * @type {number}
     * @memberof Handheld
     */
    'signalRssi'?: number;
    /**
     * 
     * @type {number}
     * @memberof Handheld
     */
    'batteryIndicator'?: number;
    /**
     * 
     * @type {HandheldLimitation}
     * @memberof Handheld
     */
    'limitation'?: HandheldLimitation;
}
/**
 * 
 * @export
 * @interface HandheldConnectionDto
 */
export interface HandheldConnectionDto {
    /**
     * 
     * @type {number}
     * @memberof HandheldConnectionDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HandheldConnectionDto
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface HandheldLimitation
 */
export interface HandheldLimitation {
    /**
     * 
     * @type {boolean}
     * @memberof HandheldLimitation
     */
    'noOpen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandheldLimitation
     */
    'noClose'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandheldLimitation
     */
    'noUpDown'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandheldLimitation
     */
    'limitedOperation'?: boolean;
}
/**
 * 
 * @export
 * @interface Imu
 */
export interface Imu {
    /**
     * 
     * @type {number}
     * @memberof Imu
     */
    'orientation': number;
    /**
     * 
     * @type {boolean}
     * @memberof Imu
     */
    'plSensEnable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Imu
     */
    'plAngleToAnaOutEn': boolean;
    /**
     * 
     * @type {string}
     * @memberof Imu
     */
    'unused': string;
    /**
     * 
     * @type {number}
     * @memberof Imu
     */
    'angleCorrection': number;
}
/**
 * 
 * @export
 * @interface InputInterface
 */
export interface InputInterface {
    /**
     * 
     * @type {boolean}
     * @memberof InputInterface
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof InputInterface
     */
    'singleClick'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof InputInterface
     */
    'doubleClick'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface InputInterfaceV2
 */
export interface InputInterfaceV2 {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof InputInterfaceV2
     */
    'singleClick'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof InputInterfaceV2
     */
    'doubleClick'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface IoMonitorConfig
 */
export interface IoMonitorConfig {
    /**
     * 
     * @type {boolean}
     * @memberof IoMonitorConfig
     */
    'shutdownSeparately': boolean;
    /**
     * 
     * @type {string}
     * @memberof IoMonitorConfig
     */
    'unused': string;
    /**
     * 
     * @type {number}
     * @memberof IoMonitorConfig
     */
    'lowTh': number;
    /**
     * 
     * @type {number}
     * @memberof IoMonitorConfig
     */
    'highThP1Io': number;
    /**
     * 
     * @type {number}
     * @memberof IoMonitorConfig
     */
    'highThP1Vbn': number;
    /**
     * 
     * @type {number}
     * @memberof IoMonitorConfig
     */
    'highThP2Io': number;
    /**
     * 
     * @type {number}
     * @memberof IoMonitorConfig
     */
    'highThP2Vbn': number;
}
/**
 * 
 * @export
 * @interface Lock
 */
export interface Lock {
    /**
     * 
     * @type {boolean}
     * @memberof Lock
     */
    'autoLock'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lock
     */
    'manualLock'?: boolean;
}
/**
 * 
 * @export
 * @interface Logger
 */
export interface Logger {
    /**
     * 
     * @type {number}
     * @memberof Logger
     */
    'enabledMsgs': number;
}
/**
 * 
 * @export
 * @interface LogicOperation
 */
export interface LogicOperation {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof LogicOperation
     */
    'logicOperations': Array<boolean>;
}
/**
 * 
 * @export
 * @interface LoginOption
 */
export interface LoginOption {
    /**
     * 
     * @type {boolean}
     * @memberof LoginOption
     */
    'loginLimitedToSideZone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginOption
     */
    'allowFastLogin': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginOption
     */
    'unused': string;
}
/**
 * 
 * @export
 * @interface MonitorAutoTiltDown
 */
export interface MonitorAutoTiltDown {
    /**
     * 
     * @type {boolean}
     * @memberof MonitorAutoTiltDown
     */
    'input'?: boolean;
}
/**
 * 
 * @export
 * @interface MonitorCableControl
 */
export interface MonitorCableControl {
    /**
     * 
     * @type {number}
     * @memberof MonitorCableControl
     */
    'activeControl'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof MonitorCableControl
     */
    'activeFunction'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof MonitorCableControl
     */
    'blockedControls'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface MonitorPacketDto
 */
export interface MonitorPacketDto {
    /**
     * 
     * @type {number}
     * @memberof MonitorPacketDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitorPacketDto
     */
    'counter'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitorPacketDto
     */
    'status'?: number;
    /**
     * 
     * @type {Array<AbstractMonitorMessage>}
     * @memberof MonitorPacketDto
     */
    'payloads'?: Array<AbstractMonitorMessage>;
}
/**
 * 
 * @export
 * @interface Output
 */
export interface Output {
    /**
     * 
     * @type {number}
     * @memberof Output
     */
    'fheConf': number;
    /**
     * 
     * @type {number}
     * @memberof Output
     */
    'ioInvert': number;
    /**
     * 
     * @type {LogicOperation}
     * @memberof Output
     */
    'logicOperation': LogicOperation;
    /**
     * 
     * @type {boolean}
     * @memberof Output
     */
    'openMotorOffInAngleSector4AndWorkZone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Output
     */
    'openMotorOffInAngleSector4AndSideZone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Output
     */
    'openMotorOffInAngleSector4AndBackZone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Output
     */
    'openMotorOffInAngleSector4AndPlZone': boolean;
    /**
     * 
     * @type {string}
     * @memberof Output
     */
    'unused': string;
    /**
     * 
     * @type {boolean}
     * @memberof Output
     */
    'motorOffOnAnaInHigh': boolean;
    /**
     * 
     * @type {Array<OutputConfig>}
     * @memberof Output
     */
    'outputConfigTable': Array<OutputConfig>;
}
/**
 * 
 * @export
 * @interface OutputA
 */
export interface OutputA {
    /**
     * 
     * @type {boolean}
     * @memberof OutputA
     */
    'output1Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputA
     */
    'output2Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputA
     */
    'output3Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputA
     */
    'output4Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputA
     */
    'serialSwitchAActivated'?: boolean;
}
/**
 * 
 * @export
 * @interface OutputB
 */
export interface OutputB {
    /**
     * 
     * @type {boolean}
     * @memberof OutputB
     */
    'output5Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputB
     */
    'output6Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputB
     */
    'output7Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputB
     */
    'output8Activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputB
     */
    'serialSwitchBActivated'?: boolean;
}
/**
 * 
 * @export
 * @interface OutputConfig
 */
export interface OutputConfig {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof OutputConfig
     */
    'hsBtn': Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof OutputConfig
     */
    'angleSectors': Array<boolean>;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'cableControlOpen': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'cableControlClose': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'cableControlUp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'cableControlDown': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'vred': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'alock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'mlock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'sideZone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'sideZone50Hz': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'autoTiltUp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'autoTiltDown': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputConfig
     */
    'fheOutHighSide': boolean;
}
/**
 * 
 * @export
 * @interface OutputMask
 */
export interface OutputMask {
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'motor': number;
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'liftUpDown': number;
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'movementDown': number;
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'tilt': number;
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'fastTilt': number;
    /**
     * 
     * @type {number}
     * @memberof OutputMask
     */
    'overrideMask': number;
}
/**
 * 
 * @export
 * @interface Pc
 */
export interface Pc {
    /**
     * 
     * @type {boolean}
     * @memberof Pc
     */
    'shutdownDisable': boolean;
    /**
     * 
     * @type {number}
     * @memberof Pc
     */
    'reserve': number;
}
/**
 * 
 * @export
 * @interface Pfr
 */
export interface Pfr {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof Pfr
     */
    'hsTable': Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof Pfr
     */
    'amtTable': Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface Platform
 */
export interface Platform {
    /**
     * 
     * @type {number}
     * @memberof Platform
     */
    'mean': number;
    /**
     * 
     * @type {number}
     * @memberof Platform
     */
    'delta': number;
}
/**
 * 
 * @export
 * @interface PlatformSensorV0V1
 */
export interface PlatformSensorV0V1 {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PlatformSensorV0V1
     */
    'singleClick'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PlatformSensorV0V1
     */
    'doubleClick'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface PlatformSensorV2
 */
export interface PlatformSensorV2 {
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'state'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'platformAngle'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'angularRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'meanAngularRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'maxAngularRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'outBlinkOnTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSensorV2
     */
    'outBlinkOffTime'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PlatformSensorV2
     */
    'singleClick'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PlatformSensorV2
     */
    'doubleClick'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface Powerdown
 */
export interface Powerdown {
    /**
     * 
     * @type {number}
     * @memberof Powerdown
     */
    'timeToPowerdown': number;
}
/**
 * 
 * @export
 * @interface Principal200Response
 */
export interface Principal200Response {
    /**
     * 
     * @type {string}
     * @memberof Principal200Response
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PsIoConf
 */
export interface PsIoConf {
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PsIoConf
     */
    'hsBtn': Array<boolean>;
    /**
     * 
     * @type {string}
     * @memberof PsIoConf
     */
    'angleSectors': string;
    /**
     * 
     * @type {boolean}
     * @memberof PsIoConf
     */
    'vred': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsIoConf
     */
    'alock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsIoConf
     */
    'mlock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsIoConf
     */
    'sideZone': boolean;
    /**
     * 
     * @type {number}
     * @memberof PsIoConf
     */
    'reserve': number;
}
/**
 * 
 * @export
 * @interface Psio
 */
export interface Psio {
    /**
     * 
     * @type {number}
     * @memberof Psio
     */
    'ioInvert': number;
    /**
     * 
     * @type {number}
     * @memberof Psio
     */
    'logicOperation': number;
    /**
     * 
     * @type {Array<PsIoConf>}
     * @memberof Psio
     */
    'psIoConfTable': Array<PsIoConf>;
    /**
     * 
     * @type {number}
     * @memberof Psio
     */
    'outBlinkOnTime': number;
    /**
     * 
     * @type {number}
     * @memberof Psio
     */
    'outBlinkOffTime': number;
}
/**
 * 
 * @export
 * @interface ResForDev
 */
export interface ResForDev {
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter1': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter2': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter3': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter4': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter5': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter6': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter7': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter8': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter9': number;
    /**
     * 
     * @type {number}
     * @memberof ResForDev
     */
    'parameter10': number;
}
/**
 * 
 * @export
 * @interface RssiDetection
 */
export interface RssiDetection {
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'filterUp': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'filterDown': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'filterUpNoTas': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'filterDownNoTas': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'offsetInitMean': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'maxDeltaRssiHsRcv': number;
    /**
     * 
     * @type {number}
     * @memberof RssiDetection
     */
    'maxCntNoRssi': number;
}
/**
 * 
 * @export
 * @interface RssiZone
 */
export interface RssiZone {
    /**
     * 
     * @type {Platform}
     * @memberof RssiZone
     */
    'platform': Platform;
    /**
     * 
     * @type {Back}
     * @memberof RssiZone
     */
    'back': Back;
    /**
     * 
     * @type {Side}
     * @memberof RssiZone
     */
    'side': Side;
    /**
     * 
     * @type {Work}
     * @memberof RssiZone
     */
    'work': Work;
    /**
     * 
     * @type {Danger}
     * @memberof RssiZone
     */
    'danger': Danger;
    /**
     * 
     * @type {ZoneHyst}
     * @memberof RssiZone
     */
    'hyst': ZoneHyst;
    /**
     * 
     * @type {ZoneFilter}
     * @memberof RssiZone
     */
    'filter': ZoneFilter;
    /**
     * 
     * @type {RssiDetection}
     * @memberof RssiZone
     */
    'rssiDetection': RssiDetection;
    /**
     * 
     * @type {ZoneDisable}
     * @memberof RssiZone
     */
    'zoneDisable': ZoneDisable;
    /**
     * 
     * @type {RssiZoneOptions}
     * @memberof RssiZone
     */
    'rssiZoneOptions': RssiZoneOptions;
    /**
     * 
     * @type {number}
     * @memberof RssiZone
     */
    'vehicleType': number;
}
/**
 * 
 * @export
 * @interface RssiZoneOptions
 */
export interface RssiZoneOptions {
    /**
     * 
     * @type {boolean}
     * @memberof RssiZoneOptions
     */
    'singlePositionSensModeEn': boolean;
    /**
     * 
     * @type {string}
     * @memberof RssiZoneOptions
     */
    'reserve': string;
}
/**
 * 
 * @export
 * @interface RxPlsVersion
 */
export interface RxPlsVersion {
    /**
     * 
     * @type {DeviceVersion}
     * @memberof RxPlsVersion
     */
    'rx'?: DeviceVersion;
    /**
     * 
     * @type {DeviceVersion}
     * @memberof RxPlsVersion
     */
    'pls'?: DeviceVersion;
}
/**
 * 
 * @export
 * @interface SerHs
 */
export interface SerHs {
    /**
     * 
     * @type {number}
     * @memberof SerHs
     */
    'maxNofHsAllowed': number;
    /**
     * 
     * @type {number}
     * @memberof SerHs
     */
    'nofHsSaved': number;
    /**
     * 
     * @type {number}
     * @memberof SerHs
     */
    'unused1': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SerHs
     */
    'table': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SerHs
     */
    'crc': number;
}
/**
 * 
 * @export
 * @interface Side
 */
export interface Side {
    /**
     * 
     * @type {number}
     * @memberof Side
     */
    'max': number;
    /**
     * 
     * @type {number}
     * @memberof Side
     */
    'delta': number;
    /**
     * 
     * @type {number}
     * @memberof Side
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface SignedConfigurationAbstractConfiguration
 */
export interface SignedConfigurationAbstractConfiguration {
    /**
     * 
     * @type {SignedConfigurationAbstractConfigurationConfiguration}
     * @memberof SignedConfigurationAbstractConfiguration
     */
    'configuration'?: SignedConfigurationAbstractConfigurationConfiguration;
    /**
     * 
     * @type {string}
     * @memberof SignedConfigurationAbstractConfiguration
     */
    'digitalSignature'?: string;
    /**
     * 
     * @type {DeviceConfigurationMetaData}
     * @memberof SignedConfigurationAbstractConfiguration
     */
    '_metaInformation'?: DeviceConfigurationMetaData;
}
/**
 * @type SignedConfigurationAbstractConfigurationConfiguration
 * @export
 */
export type SignedConfigurationAbstractConfigurationConfiguration = DeviceConfiguration | DeviceTransmitterMemoryConfiguration;

/**
 * 
 * @export
 * @interface SignedConfigurationDeviceConfiguration
 */
export interface SignedConfigurationDeviceConfiguration {
    /**
     * 
     * @type {DeviceConfiguration}
     * @memberof SignedConfigurationDeviceConfiguration
     */
    'configuration'?: DeviceConfiguration;
    /**
     * 
     * @type {string}
     * @memberof SignedConfigurationDeviceConfiguration
     */
    'digitalSignature'?: string;
    /**
     * 
     * @type {DeviceConfigurationMetaData}
     * @memberof SignedConfigurationDeviceConfiguration
     */
    '_metaInformation'?: DeviceConfigurationMetaData;
}
/**
 * 
 * @export
 * @interface SignedConfigurationDeviceTransmitterMemoryConfiguration
 */
export interface SignedConfigurationDeviceTransmitterMemoryConfiguration {
    /**
     * 
     * @type {DeviceTransmitterMemoryConfiguration}
     * @memberof SignedConfigurationDeviceTransmitterMemoryConfiguration
     */
    'configuration'?: DeviceTransmitterMemoryConfiguration;
    /**
     * 
     * @type {string}
     * @memberof SignedConfigurationDeviceTransmitterMemoryConfiguration
     */
    'digitalSignature'?: string;
    /**
     * 
     * @type {DeviceConfigurationMetaData}
     * @memberof SignedConfigurationDeviceTransmitterMemoryConfiguration
     */
    '_metaInformation'?: DeviceConfigurationMetaData;
}
/**
 * 
 * @export
 * @interface StatusPayloadV0
 */
export interface StatusPayloadV0 {
    /**
     * 
     * @type {string}
     * @memberof StatusPayloadV0
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'vehicleBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'platformAngle'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'errorCode'?: number;
    /**
     * 
     * @type {HandheldLimitation}
     * @memberof StatusPayloadV0
     */
    'handheldLimitation'?: HandheldLimitation;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'hhZone'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof StatusPayloadV0
     */
    'hhButtons'?: Array<boolean>;
    /**
     * 
     * @type {OutputA}
     * @memberof StatusPayloadV0
     */
    'outputA'?: OutputA;
    /**
     * 
     * @type {OutputB}
     * @memberof StatusPayloadV0
     */
    'outputB'?: OutputB;
    /**
     * 
     * @type {ActivatedSensorsV0V1}
     * @memberof StatusPayloadV0
     */
    'activatedSensors'?: ActivatedSensorsV0V1;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'hhSignal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV0
     */
    'wlanIndex'?: number;
    /**
     * 
     * @type {MonitorAutoTiltDown}
     * @memberof StatusPayloadV0
     */
    'autoTiltDown'?: MonitorAutoTiltDown;
    /**
     * 
     * @type {CabinSwitch}
     * @memberof StatusPayloadV0
     */
    'cabinSwitch'?: CabinSwitch;
}
/**
 * 
 * @export
 * @interface StatusPayloadV1
 */
export interface StatusPayloadV1 {
    /**
     * 
     * @type {string}
     * @memberof StatusPayloadV1
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'vehicleBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'platformAngle'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'errorCode'?: number;
    /**
     * 
     * @type {HandheldLimitation}
     * @memberof StatusPayloadV1
     */
    'handheldLimitation'?: HandheldLimitation;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'hhZone'?: number;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof StatusPayloadV1
     */
    'hhButtons'?: Array<boolean>;
    /**
     * 
     * @type {OutputA}
     * @memberof StatusPayloadV1
     */
    'outputA'?: OutputA;
    /**
     * 
     * @type {OutputB}
     * @memberof StatusPayloadV1
     */
    'outputB'?: OutputB;
    /**
     * 
     * @type {ActivatedSensorsV0V1}
     * @memberof StatusPayloadV1
     */
    'activatedSensors'?: ActivatedSensorsV0V1;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'hhSignal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV1
     */
    'wlanIndex'?: number;
    /**
     * 
     * @type {MonitorAutoTiltDown}
     * @memberof StatusPayloadV1
     */
    'autoTiltDown'?: MonitorAutoTiltDown;
    /**
     * 
     * @type {CabinSwitch}
     * @memberof StatusPayloadV1
     */
    'cabinSwitch'?: CabinSwitch;
    /**
     * 
     * @type {PlatformSensorV0V1}
     * @memberof StatusPayloadV1
     */
    'platformSensor'?: PlatformSensorV0V1;
    /**
     * 
     * @type {InputInterface}
     * @memberof StatusPayloadV1
     */
    'inputInterface'?: InputInterface;
    /**
     * 
     * @type {MonitorCableControl}
     * @memberof StatusPayloadV1
     */
    'monitorCableControl'?: MonitorCableControl;
    /**
     * 
     * @type {SystemLimitation}
     * @memberof StatusPayloadV1
     */
    'systemLimitation'?: SystemLimitation;
}
/**
 * 
 * @export
 * @interface StatusPayloadV2
 */
export interface StatusPayloadV2 {
    /**
     * 
     * @type {string}
     * @memberof StatusPayloadV2
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV2
     */
    'errorCode'?: number;
    /**
     * 
     * @type {OutputA}
     * @memberof StatusPayloadV2
     */
    'outputA'?: OutputA;
    /**
     * 
     * @type {OutputB}
     * @memberof StatusPayloadV2
     */
    'outputB'?: OutputB;
    /**
     * 
     * @type {SystemLimitation}
     * @memberof StatusPayloadV2
     */
    'systemLimitation'?: SystemLimitation;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV2
     */
    'vehicleBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV2
     */
    'analogIo'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusPayloadV2
     */
    'wlanIndex'?: number;
    /**
     * 
     * @type {Handheld}
     * @memberof StatusPayloadV2
     */
    'handheld'?: Handheld;
    /**
     * 
     * @type {ActivatedSensorsV2}
     * @memberof StatusPayloadV2
     */
    'activatedSensors'?: ActivatedSensorsV2;
    /**
     * 
     * @type {PlatformSensorV2}
     * @memberof StatusPayloadV2
     */
    'platformSensor'?: PlatformSensorV2;
    /**
     * 
     * @type {MonitorAutoTiltDown}
     * @memberof StatusPayloadV2
     */
    'autoTiltDown'?: MonitorAutoTiltDown;
    /**
     * 
     * @type {CabinSwitch}
     * @memberof StatusPayloadV2
     */
    'cabinSwitch'?: CabinSwitch;
    /**
     * 
     * @type {InputInterfaceV2}
     * @memberof StatusPayloadV2
     */
    'inputInterface'?: InputInterfaceV2;
    /**
     * 
     * @type {MonitorCableControl}
     * @memberof StatusPayloadV2
     */
    'monitorCableControl'?: MonitorCableControl;
    /**
     * 
     * @type {Lock}
     * @memberof StatusPayloadV2
     */
    'lock'?: Lock;
}
/**
 * 
 * @export
 * @interface SystemLimitation
 */
export interface SystemLimitation {
    /**
     * 
     * @type {boolean}
     * @memberof SystemLimitation
     */
    'motor'?: boolean;
}
/**
 * 
 * @export
 * @interface TerminalDebug
 */
export interface TerminalDebug {
    /**
     * 
     * @type {boolean}
     * @memberof TerminalDebug
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof TerminalDebug
     */
    'reserve': number;
}
/**
 * 
 * @export
 * @interface Up
 */
export interface Up {
    /**
     * 
     * @type {boolean}
     * @memberof Up
     */
    'enable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Up
     */
    'startOnlyAfterNewButtonPress': boolean;
    /**
     * 
     * @type {string}
     * @memberof Up
     */
    'reserve': string;
}
/**
 * 
 * @export
 * @interface UserMetada
 */
export interface UserMetada {
    /**
     * 
     * @type {string}
     * @memberof UserMetada
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMetada
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMetada
     */
    'manufacturerId'?: string;
}
/**
 * 
 * @export
 * @interface Variokey
 */
export interface Variokey {
    /**
     * 
     * @type {Array<VariokeyItem>}
     * @memberof Variokey
     */
    'variokeys'?: Array<VariokeyItem>;
}
/**
 * 
 * @export
 * @interface VariokeyItem
 */
export interface VariokeyItem {
    /**
     * 
     * @type {string}
     * @memberof VariokeyItem
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VariokeyItem
     */
    'index'?: number;
}
/**
 * 
 * @export
 * @interface Vred
 */
export interface Vred {
    /**
     * 
     * @type {string}
     * @memberof Vred
     */
    'angleMinDeg': string;
    /**
     * 
     * @type {string}
     * @memberof Vred
     */
    'angleMaxDeg': string;
    /**
     * 
     * @type {boolean}
     * @memberof Vred
     */
    'fastIfSideZoneEnable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Vred
     */
    'internalSpeedLimitEnable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Vred
     */
    'intelligentVRedEnable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Vred
     */
    'inverted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Vred
     */
    'reserve': string;
}
/**
 * 
 * @export
 * @interface Work
 */
export interface Work {
    /**
     * 
     * @type {number}
     * @memberof Work
     */
    'mean': number;
    /**
     * 
     * @type {number}
     * @memberof Work
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface ZoneDisable
 */
export interface ZoneDisable {
    /**
     * 
     * @type {boolean}
     * @memberof ZoneDisable
     */
    'work': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneDisable
     */
    'side': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneDisable
     */
    'back': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneDisable
     */
    'platform': boolean;
    /**
     * 
     * @type {string}
     * @memberof ZoneDisable
     */
    'reserve': string;
}
/**
 * 
 * @export
 * @interface ZoneFilter
 */
export interface ZoneFilter {
    /**
     * 
     * @type {number}
     * @memberof ZoneFilter
     */
    'platformZone': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneFilter
     */
    'backZone': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneFilter
     */
    'sideZone': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneFilter
     */
    'workZone': number;
}
/**
 * 
 * @export
 * @interface ZoneHyst
 */
export interface ZoneHyst {
    /**
     * 
     * @type {number}
     * @memberof ZoneHyst
     */
    'sideZoneActivation': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneHyst
     */
    'workZoneActivation': number;
}

/**
 * DeviceConfigurationControllerApi - axios parameter creator
 * @export
 */
export const DeviceConfigurationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignedDeviceConfigurationFile: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('downloadSignedDeviceConfigurationFile', 'deviceId', deviceId)
            const localVarPath = `/DeviceConfigurationController/download/DeviceConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignedDeviceTransmitterMemoryConfigurationFile: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('downloadSignedDeviceTransmitterMemoryConfigurationFile', 'deviceId', deviceId)
            const localVarPath = `/DeviceConfigurationController/download/DeviceTransmitterMemoryConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDeviceConfiguration: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getSignedDeviceConfiguration', 'deviceId', deviceId)
            const localVarPath = `/DeviceConfigurationController/DeviceConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDeviceTransmitterMemoryConfiguration: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getSignedDeviceTransmitterMemoryConfiguration', 'deviceId', deviceId)
            const localVarPath = `/DeviceConfigurationController/DeviceTransmitterMemoryConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariokeyList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DeviceConfigurationController/variokeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceConfiguration} deviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceConfiguration: async (deviceId: number, deviceConfiguration: DeviceConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDeviceConfiguration', 'deviceId', deviceId)
            // verify required parameter 'deviceConfiguration' is not null or undefined
            assertParamExists('updateDeviceConfiguration', 'deviceConfiguration', deviceConfiguration)
            const localVarPath = `/DeviceConfigurationController/DeviceConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceTransmitterMemoryConfiguration} deviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceTransmitterMemoryConfiguration: async (deviceId: number, deviceTransmitterMemoryConfiguration: DeviceTransmitterMemoryConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDeviceTransmitterMemoryConfiguration', 'deviceId', deviceId)
            // verify required parameter 'deviceTransmitterMemoryConfiguration' is not null or undefined
            assertParamExists('updateDeviceTransmitterMemoryConfiguration', 'deviceTransmitterMemoryConfiguration', deviceTransmitterMemoryConfiguration)
            const localVarPath = `/DeviceConfigurationController/DeviceTransmitterMemoryConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceTransmitterMemoryConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceConfiguration} signedConfigurationDeviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignedDeviceConfiguration: async (deviceId: number, signedConfigurationDeviceConfiguration: SignedConfigurationDeviceConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateSignedDeviceConfiguration', 'deviceId', deviceId)
            // verify required parameter 'signedConfigurationDeviceConfiguration' is not null or undefined
            assertParamExists('updateSignedDeviceConfiguration', 'signedConfigurationDeviceConfiguration', signedConfigurationDeviceConfiguration)
            const localVarPath = `/DeviceConfigurationController/DeviceConfiguration/signed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedConfigurationDeviceConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceTransmitterMemoryConfiguration} signedConfigurationDeviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignedDeviceTransmitterMemoryConfiguration: async (deviceId: number, signedConfigurationDeviceTransmitterMemoryConfiguration: SignedConfigurationDeviceTransmitterMemoryConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateSignedDeviceTransmitterMemoryConfiguration', 'deviceId', deviceId)
            // verify required parameter 'signedConfigurationDeviceTransmitterMemoryConfiguration' is not null or undefined
            assertParamExists('updateSignedDeviceTransmitterMemoryConfiguration', 'signedConfigurationDeviceTransmitterMemoryConfiguration', signedConfigurationDeviceTransmitterMemoryConfiguration)
            const localVarPath = `/DeviceConfigurationController/DeviceTransmitterMemoryConfiguration/signed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedConfigurationDeviceTransmitterMemoryConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignedConfigurationAbstractConfiguration} signedConfigurationAbstractConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSignedConfiguration: async (signedConfigurationAbstractConfiguration: SignedConfigurationAbstractConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signedConfigurationAbstractConfiguration' is not null or undefined
            assertParamExists('validateSignedConfiguration', 'signedConfigurationAbstractConfiguration', signedConfigurationAbstractConfiguration)
            const localVarPath = `/DeviceConfigurationController/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedConfigurationAbstractConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceConfigurationControllerApi - functional programming interface
 * @export
 */
export const DeviceConfigurationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceConfigurationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSignedDeviceConfigurationFile(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSignedDeviceConfigurationFile(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.downloadSignedDeviceConfigurationFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.downloadSignedDeviceTransmitterMemoryConfigurationFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedDeviceConfiguration(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedConfigurationDeviceConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedDeviceConfiguration(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.getSignedDeviceConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedDeviceTransmitterMemoryConfiguration(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedConfigurationDeviceTransmitterMemoryConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedDeviceTransmitterMemoryConfiguration(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.getSignedDeviceTransmitterMemoryConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariokeyList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Variokey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariokeyList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.getVariokeyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceConfiguration} deviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceConfiguration(deviceId: number, deviceConfiguration: DeviceConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceConfiguration(deviceId, deviceConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.updateDeviceConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceTransmitterMemoryConfiguration} deviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceTransmitterMemoryConfiguration(deviceId: number, deviceTransmitterMemoryConfiguration: DeviceTransmitterMemoryConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceTransmitterMemoryConfiguration(deviceId, deviceTransmitterMemoryConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.updateDeviceTransmitterMemoryConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceConfiguration} signedConfigurationDeviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSignedDeviceConfiguration(deviceId: number, signedConfigurationDeviceConfiguration: SignedConfigurationDeviceConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSignedDeviceConfiguration(deviceId, signedConfigurationDeviceConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.updateSignedDeviceConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceTransmitterMemoryConfiguration} signedConfigurationDeviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSignedDeviceTransmitterMemoryConfiguration(deviceId: number, signedConfigurationDeviceTransmitterMemoryConfiguration: SignedConfigurationDeviceTransmitterMemoryConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSignedDeviceTransmitterMemoryConfiguration(deviceId, signedConfigurationDeviceTransmitterMemoryConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.updateSignedDeviceTransmitterMemoryConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SignedConfigurationAbstractConfiguration} signedConfigurationAbstractConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSignedConfiguration(signedConfigurationAbstractConfiguration: SignedConfigurationAbstractConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedConfigurationAbstractConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSignedConfiguration(signedConfigurationAbstractConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationControllerApi.validateSignedConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceConfigurationControllerApi - factory interface
 * @export
 */
export const DeviceConfigurationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceConfigurationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignedDeviceConfigurationFile(deviceId: number, options?: any): AxiosPromise<File> {
            return localVarFp.downloadSignedDeviceConfigurationFile(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId: number, options?: any): AxiosPromise<File> {
            return localVarFp.downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDeviceConfiguration(deviceId: number, options?: any): AxiosPromise<SignedConfigurationDeviceConfiguration> {
            return localVarFp.getSignedDeviceConfiguration(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDeviceTransmitterMemoryConfiguration(deviceId: number, options?: any): AxiosPromise<SignedConfigurationDeviceTransmitterMemoryConfiguration> {
            return localVarFp.getSignedDeviceTransmitterMemoryConfiguration(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariokeyList(options?: any): AxiosPromise<Variokey> {
            return localVarFp.getVariokeyList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceConfiguration} deviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceConfiguration(deviceId: number, deviceConfiguration: DeviceConfiguration, options?: any): AxiosPromise<DeviceConfigurationUpdateDto> {
            return localVarFp.updateDeviceConfiguration(deviceId, deviceConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {DeviceTransmitterMemoryConfiguration} deviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceTransmitterMemoryConfiguration(deviceId: number, deviceTransmitterMemoryConfiguration: DeviceTransmitterMemoryConfiguration, options?: any): AxiosPromise<DeviceConfigurationUpdateDto> {
            return localVarFp.updateDeviceTransmitterMemoryConfiguration(deviceId, deviceTransmitterMemoryConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceConfiguration} signedConfigurationDeviceConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignedDeviceConfiguration(deviceId: number, signedConfigurationDeviceConfiguration: SignedConfigurationDeviceConfiguration, options?: any): AxiosPromise<DeviceConfigurationUpdateDto> {
            return localVarFp.updateSignedDeviceConfiguration(deviceId, signedConfigurationDeviceConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {SignedConfigurationDeviceTransmitterMemoryConfiguration} signedConfigurationDeviceTransmitterMemoryConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignedDeviceTransmitterMemoryConfiguration(deviceId: number, signedConfigurationDeviceTransmitterMemoryConfiguration: SignedConfigurationDeviceTransmitterMemoryConfiguration, options?: any): AxiosPromise<DeviceConfigurationUpdateDto> {
            return localVarFp.updateSignedDeviceTransmitterMemoryConfiguration(deviceId, signedConfigurationDeviceTransmitterMemoryConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignedConfigurationAbstractConfiguration} signedConfigurationAbstractConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSignedConfiguration(signedConfigurationAbstractConfiguration: SignedConfigurationAbstractConfiguration, options?: any): AxiosPromise<SignedConfigurationAbstractConfiguration> {
            return localVarFp.validateSignedConfiguration(signedConfigurationAbstractConfiguration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceConfigurationControllerApi - object-oriented interface
 * @export
 * @class DeviceConfigurationControllerApi
 * @extends {BaseAPI}
 */
export class DeviceConfigurationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public downloadSignedDeviceConfigurationFile(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).downloadSignedDeviceConfigurationFile(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).downloadSignedDeviceTransmitterMemoryConfigurationFile(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public getSignedDeviceConfiguration(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).getSignedDeviceConfiguration(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public getSignedDeviceTransmitterMemoryConfiguration(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).getSignedDeviceTransmitterMemoryConfiguration(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public getVariokeyList(options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).getVariokeyList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {DeviceConfiguration} deviceConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public updateDeviceConfiguration(deviceId: number, deviceConfiguration: DeviceConfiguration, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).updateDeviceConfiguration(deviceId, deviceConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {DeviceTransmitterMemoryConfiguration} deviceTransmitterMemoryConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public updateDeviceTransmitterMemoryConfiguration(deviceId: number, deviceTransmitterMemoryConfiguration: DeviceTransmitterMemoryConfiguration, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).updateDeviceTransmitterMemoryConfiguration(deviceId, deviceTransmitterMemoryConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {SignedConfigurationDeviceConfiguration} signedConfigurationDeviceConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public updateSignedDeviceConfiguration(deviceId: number, signedConfigurationDeviceConfiguration: SignedConfigurationDeviceConfiguration, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).updateSignedDeviceConfiguration(deviceId, signedConfigurationDeviceConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {SignedConfigurationDeviceTransmitterMemoryConfiguration} signedConfigurationDeviceTransmitterMemoryConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public updateSignedDeviceTransmitterMemoryConfiguration(deviceId: number, signedConfigurationDeviceTransmitterMemoryConfiguration: SignedConfigurationDeviceTransmitterMemoryConfiguration, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).updateSignedDeviceTransmitterMemoryConfiguration(deviceId, signedConfigurationDeviceTransmitterMemoryConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignedConfigurationAbstractConfiguration} signedConfigurationAbstractConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationControllerApi
     */
    public validateSignedConfiguration(signedConfigurationAbstractConfiguration: SignedConfigurationAbstractConfiguration, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationControllerApiFp(this.configuration).validateSignedConfiguration(signedConfigurationAbstractConfiguration, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceMonitoringControllerApi - axios parameter creator
 * @export
 */
export const DeviceMonitoringControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableMonitoring: async (index: string, deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'index' is not null or undefined
            assertParamExists('disableMonitoring', 'index', index)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('disableMonitoring', 'deviceId', deviceId)
            const localVarPath = `/DeviceMonitoringController/monitoring/{index}`
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMonitoring: async (index: string, deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'index' is not null or undefined
            assertParamExists('enableMonitoring', 'index', index)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('enableMonitoring', 'deviceId', deviceId)
            const localVarPath = `/DeviceMonitoringController/monitoring/{index}`
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion1: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getVersion1', 'deviceId', deviceId)
            const localVarPath = `/DeviceMonitoringController/getVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceMonitoringControllerApi - functional programming interface
 * @export
 */
export const DeviceMonitoringControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceMonitoringControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableMonitoring(index: string, deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableMonitoring(index, deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceMonitoringControllerApi.disableMonitoring']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableMonitoring(index: string, deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableMonitoring(index, deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceMonitoringControllerApi.enableMonitoring']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion1(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RxPlsVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion1(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceMonitoringControllerApi.getVersion1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceMonitoringControllerApi - factory interface
 * @export
 */
export const DeviceMonitoringControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceMonitoringControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableMonitoring(index: string, deviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.disableMonitoring(index, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} index 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMonitoring(index: string, deviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.enableMonitoring(index, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion1(deviceId: number, options?: any): AxiosPromise<RxPlsVersion> {
            return localVarFp.getVersion1(deviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceMonitoringControllerApi - object-oriented interface
 * @export
 * @class DeviceMonitoringControllerApi
 * @extends {BaseAPI}
 */
export class DeviceMonitoringControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} index 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceMonitoringControllerApi
     */
    public disableMonitoring(index: string, deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceMonitoringControllerApiFp(this.configuration).disableMonitoring(index, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} index 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceMonitoringControllerApi
     */
    public enableMonitoring(index: string, deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceMonitoringControllerApiFp(this.configuration).enableMonitoring(index, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceMonitoringControllerApi
     */
    public getVersion1(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceMonitoringControllerApiFp(this.configuration).getVersion1(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceRegistryControllerApi - axios parameter creator
 * @export
 */
export const DeviceRegistryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptDeviceRestart: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('attemptDeviceRestart', 'deviceId', deviceId)
            const localVarPath = `/DeviceRegistryController/restart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevice: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDevice', 'deviceId', deviceId)
            const localVarPath = `/DeviceRegistryController/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DeviceRegistryController/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDevices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DeviceRegistryController/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getVersion', 'deviceId', deviceId)
            const localVarPath = `/DeviceRegistryController/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceRegistryControllerApi - functional programming interface
 * @export
 */
export const DeviceRegistryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceRegistryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attemptDeviceRestart(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attemptDeviceRestart(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceRegistryControllerApi.attemptDeviceRestart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevice(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceRegistryControllerApi.getDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceRegistryControllerApi.getManufacturers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDevices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDevices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceRegistryControllerApi.getUserDevices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RxPlsVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceRegistryControllerApi.getVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceRegistryControllerApi - factory interface
 * @export
 */
export const DeviceRegistryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceRegistryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptDeviceRestart(deviceId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.attemptDeviceRestart(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevice(deviceId: number, options?: any): AxiosPromise<Device> {
            return localVarFp.getDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturers(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getManufacturers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDevices(options?: any): AxiosPromise<Array<Device>> {
            return localVarFp.getUserDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(deviceId: number, options?: any): AxiosPromise<RxPlsVersion> {
            return localVarFp.getVersion(deviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceRegistryControllerApi - object-oriented interface
 * @export
 * @class DeviceRegistryControllerApi
 * @extends {BaseAPI}
 */
export class DeviceRegistryControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceRegistryControllerApi
     */
    public attemptDeviceRestart(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceRegistryControllerApiFp(this.configuration).attemptDeviceRestart(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceRegistryControllerApi
     */
    public getDevice(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceRegistryControllerApiFp(this.configuration).getDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceRegistryControllerApi
     */
    public getManufacturers(options?: RawAxiosRequestConfig) {
        return DeviceRegistryControllerApiFp(this.configuration).getManufacturers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceRegistryControllerApi
     */
    public getUserDevices(options?: RawAxiosRequestConfig) {
        return DeviceRegistryControllerApiFp(this.configuration).getUserDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceRegistryControllerApi
     */
    public getVersion(deviceId: number, options?: RawAxiosRequestConfig) {
        return DeviceRegistryControllerApiFp(this.configuration).getVersion(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FirmwareUpdateControllerApi - axios parameter creator
 * @export
 */
export const FirmwareUpdateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFirmwareDeviceMicroControllerVersions: async (body?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FirmwareUpdateController/deviceMicroControllerFwVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} [skipConfigurationErrors] 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirmware: async (deviceId: number, skipConfigurationErrors?: boolean, body?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateFirmware', 'deviceId', deviceId)
            const localVarPath = `/FirmwareUpdateController`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (skipConfigurationErrors !== undefined) {
                localVarQueryParameter['skipConfigurationErrors'] = skipConfigurationErrors;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmwareUpdateControllerApi - functional programming interface
 * @export
 */
export const FirmwareUpdateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmwareUpdateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFirmwareDeviceMicroControllerVersions(body?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<FirmwareDeviceMicroControllerVersionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFirmwareDeviceMicroControllerVersions(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FirmwareUpdateControllerApi.retrieveFirmwareDeviceMicroControllerVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} [skipConfigurationErrors] 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFirmware(deviceId: number, skipConfigurationErrors?: boolean, body?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFirmware(deviceId, skipConfigurationErrors, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FirmwareUpdateControllerApi.updateFirmware']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FirmwareUpdateControllerApi - factory interface
 * @export
 */
export const FirmwareUpdateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmwareUpdateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFirmwareDeviceMicroControllerVersions(body?: File, options?: any): AxiosPromise<Set<FirmwareDeviceMicroControllerVersionDto>> {
            return localVarFp.retrieveFirmwareDeviceMicroControllerVersions(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} [skipConfigurationErrors] 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFirmware(deviceId: number, skipConfigurationErrors?: boolean, body?: File, options?: any): AxiosPromise<void> {
            return localVarFp.updateFirmware(deviceId, skipConfigurationErrors, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmwareUpdateControllerApi - object-oriented interface
 * @export
 * @class FirmwareUpdateControllerApi
 * @extends {BaseAPI}
 */
export class FirmwareUpdateControllerApi extends BaseAPI {
    /**
     * 
     * @param {File} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareUpdateControllerApi
     */
    public retrieveFirmwareDeviceMicroControllerVersions(body?: File, options?: RawAxiosRequestConfig) {
        return FirmwareUpdateControllerApiFp(this.configuration).retrieveFirmwareDeviceMicroControllerVersions(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {boolean} [skipConfigurationErrors] 
     * @param {File} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareUpdateControllerApi
     */
    public updateFirmware(deviceId: number, skipConfigurationErrors?: boolean, body?: File, options?: RawAxiosRequestConfig) {
        return FirmwareUpdateControllerApiFp(this.configuration).updateFirmware(deviceId, skipConfigurationErrors, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HandheldSettingsControllerApi - axios parameter creator
 * @export
 */
export const HandheldSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} isActive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHandheldConnectionState: async (deviceId: number, isActive: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('changeHandheldConnectionState', 'deviceId', deviceId)
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('changeHandheldConnectionState', 'isActive', isActive)
            const localVarPath = `/HandheldSettingsController/connectionState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandheldConnectionState: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getHandheldConnectionState', 'deviceId', deviceId)
            const localVarPath = `/HandheldSettingsController/connectionState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandheldVariokey: async (deviceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getHandheldVariokey', 'deviceId', deviceId)
            const localVarPath = `/HandheldSettingsController/variokey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} variokey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHandheldVariokey: async (deviceId: number, variokey: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('setHandheldVariokey', 'deviceId', deviceId)
            // verify required parameter 'variokey' is not null or undefined
            assertParamExists('setHandheldVariokey', 'variokey', variokey)
            const localVarPath = `/HandheldSettingsController/variokey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (variokey !== undefined) {
                localVarQueryParameter['variokey'] = variokey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HandheldSettingsControllerApi - functional programming interface
 * @export
 */
export const HandheldSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HandheldSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} isActive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeHandheldConnectionState(deviceId: number, isActive: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandheldConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeHandheldConnectionState(deviceId, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HandheldSettingsControllerApi.changeHandheldConnectionState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHandheldConnectionState(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandheldConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHandheldConnectionState(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HandheldSettingsControllerApi.getHandheldConnectionState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHandheldVariokey(deviceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHandheldVariokey(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HandheldSettingsControllerApi.getHandheldVariokey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} variokey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHandheldVariokey(deviceId: number, variokey: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHandheldVariokey(deviceId, variokey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HandheldSettingsControllerApi.setHandheldVariokey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HandheldSettingsControllerApi - factory interface
 * @export
 */
export const HandheldSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HandheldSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} deviceId 
         * @param {boolean} isActive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHandheldConnectionState(deviceId: number, isActive: boolean, options?: any): AxiosPromise<HandheldConnectionDto> {
            return localVarFp.changeHandheldConnectionState(deviceId, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandheldConnectionState(deviceId: number, options?: any): AxiosPromise<HandheldConnectionDto> {
            return localVarFp.getHandheldConnectionState(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandheldVariokey(deviceId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getHandheldVariokey(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} variokey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHandheldVariokey(deviceId: number, variokey: number, options?: any): AxiosPromise<number> {
            return localVarFp.setHandheldVariokey(deviceId, variokey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HandheldSettingsControllerApi - object-oriented interface
 * @export
 * @class HandheldSettingsControllerApi
 * @extends {BaseAPI}
 */
export class HandheldSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} deviceId 
     * @param {boolean} isActive 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HandheldSettingsControllerApi
     */
    public changeHandheldConnectionState(deviceId: number, isActive: boolean, options?: RawAxiosRequestConfig) {
        return HandheldSettingsControllerApiFp(this.configuration).changeHandheldConnectionState(deviceId, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HandheldSettingsControllerApi
     */
    public getHandheldConnectionState(deviceId: number, options?: RawAxiosRequestConfig) {
        return HandheldSettingsControllerApiFp(this.configuration).getHandheldConnectionState(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HandheldSettingsControllerApi
     */
    public getHandheldVariokey(deviceId: number, options?: RawAxiosRequestConfig) {
        return HandheldSettingsControllerApiFp(this.configuration).getHandheldVariokey(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviceId 
     * @param {number} variokey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HandheldSettingsControllerApi
     */
    public setHandheldVariokey(deviceId: number, variokey: number, options?: RawAxiosRequestConfig) {
        return HandheldSettingsControllerApiFp(this.configuration).setHandheldVariokey(deviceId, variokey, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JwtTokenControllerApi - axios parameter creator
 * @export
 */
export const JwtTokenControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwtToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jwt-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JwtTokenControllerApi - functional programming interface
 * @export
 */
export const JwtTokenControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JwtTokenControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateJwtToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateJwtToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JwtTokenControllerApi.generateJwtToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JwtTokenControllerApi - factory interface
 * @export
 */
export const JwtTokenControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JwtTokenControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwtToken(options?: any): AxiosPromise<string> {
            return localVarFp.generateJwtToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JwtTokenControllerApi - object-oriented interface
 * @export
 * @class JwtTokenControllerApi
 * @extends {BaseAPI}
 */
export class JwtTokenControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtTokenControllerApi
     */
    public generateJwtToken(options?: RawAxiosRequestConfig) {
        return JwtTokenControllerApiFp(this.configuration).generateJwtToken(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpenApiWsDtoControllerApi - axios parameter creator
 * @export
 */
export const OpenApiWsDtoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AbstractActivatedSensors} [abstractActivatedSensors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractActivatedSensors: async (abstractActivatedSensors?: AbstractActivatedSensors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/abstractActivatedSensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractActivatedSensors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbstractMonitorMessage} [abstractMonitorMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractMonitorMessage: async (abstractMonitorMessage?: AbstractMonitorMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/abstractMonitorMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractMonitorMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbstractStatusPayload} [abstractStatusPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractStatusPayload: async (abstractStatusPayload?: AbstractStatusPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/abstractStatusPayload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractStatusPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbstractStatusPayloadV0V1} [abstractStatusPayloadV0V1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractStatusPayloadV0V1: async (abstractStatusPayloadV0V1?: AbstractStatusPayloadV0V1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/abstractStatusPayloadV0V1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractStatusPayloadV0V1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonitorPacketDto} [monitorPacketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorPacketDto: async (monitorPacketDto?: MonitorPacketDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/monitorPacketDto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorPacketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatusPayloadV0} [statusPayloadV0] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV0: async (statusPayloadV0?: StatusPayloadV0, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/statusPayloadV0`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusPayloadV0, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatusPayloadV1} [statusPayloadV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV1: async (statusPayloadV1?: StatusPayloadV1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/statusPayloadV1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusPayloadV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatusPayloadV2} [statusPayloadV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV2: async (statusPayloadV2?: StatusPayloadV2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OpenApiWsDtoController/statusPayloadV2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusPayloadV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenApiWsDtoControllerApi - functional programming interface
 * @export
 */
export const OpenApiWsDtoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenApiWsDtoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AbstractActivatedSensors} [abstractActivatedSensors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abstractActivatedSensors(abstractActivatedSensors?: AbstractActivatedSensors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abstractActivatedSensors(abstractActivatedSensors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.abstractActivatedSensors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AbstractMonitorMessage} [abstractMonitorMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abstractMonitorMessage(abstractMonitorMessage?: AbstractMonitorMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abstractMonitorMessage(abstractMonitorMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.abstractMonitorMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AbstractStatusPayload} [abstractStatusPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abstractStatusPayload(abstractStatusPayload?: AbstractStatusPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abstractStatusPayload(abstractStatusPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.abstractStatusPayload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AbstractStatusPayloadV0V1} [abstractStatusPayloadV0V1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abstractStatusPayloadV0V1(abstractStatusPayloadV0V1?: AbstractStatusPayloadV0V1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abstractStatusPayloadV0V1(abstractStatusPayloadV0V1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.abstractStatusPayloadV0V1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MonitorPacketDto} [monitorPacketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorPacketDto(monitorPacketDto?: MonitorPacketDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorPacketDto(monitorPacketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.monitorPacketDto']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StatusPayloadV0} [statusPayloadV0] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusPayloadV0(statusPayloadV0?: StatusPayloadV0, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusPayloadV0(statusPayloadV0, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.statusPayloadV0']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StatusPayloadV1} [statusPayloadV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusPayloadV1(statusPayloadV1?: StatusPayloadV1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusPayloadV1(statusPayloadV1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.statusPayloadV1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StatusPayloadV2} [statusPayloadV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusPayloadV2(statusPayloadV2?: StatusPayloadV2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusPayloadV2(statusPayloadV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenApiWsDtoControllerApi.statusPayloadV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OpenApiWsDtoControllerApi - factory interface
 * @export
 */
export const OpenApiWsDtoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenApiWsDtoControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AbstractActivatedSensors} [abstractActivatedSensors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractActivatedSensors(abstractActivatedSensors?: AbstractActivatedSensors, options?: any): AxiosPromise<void> {
            return localVarFp.abstractActivatedSensors(abstractActivatedSensors, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbstractMonitorMessage} [abstractMonitorMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractMonitorMessage(abstractMonitorMessage?: AbstractMonitorMessage, options?: any): AxiosPromise<void> {
            return localVarFp.abstractMonitorMessage(abstractMonitorMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbstractStatusPayload} [abstractStatusPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractStatusPayload(abstractStatusPayload?: AbstractStatusPayload, options?: any): AxiosPromise<void> {
            return localVarFp.abstractStatusPayload(abstractStatusPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbstractStatusPayloadV0V1} [abstractStatusPayloadV0V1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abstractStatusPayloadV0V1(abstractStatusPayloadV0V1?: AbstractStatusPayloadV0V1, options?: any): AxiosPromise<void> {
            return localVarFp.abstractStatusPayloadV0V1(abstractStatusPayloadV0V1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonitorPacketDto} [monitorPacketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorPacketDto(monitorPacketDto?: MonitorPacketDto, options?: any): AxiosPromise<void> {
            return localVarFp.monitorPacketDto(monitorPacketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatusPayloadV0} [statusPayloadV0] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV0(statusPayloadV0?: StatusPayloadV0, options?: any): AxiosPromise<void> {
            return localVarFp.statusPayloadV0(statusPayloadV0, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatusPayloadV1} [statusPayloadV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV1(statusPayloadV1?: StatusPayloadV1, options?: any): AxiosPromise<void> {
            return localVarFp.statusPayloadV1(statusPayloadV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatusPayloadV2} [statusPayloadV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusPayloadV2(statusPayloadV2?: StatusPayloadV2, options?: any): AxiosPromise<void> {
            return localVarFp.statusPayloadV2(statusPayloadV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenApiWsDtoControllerApi - object-oriented interface
 * @export
 * @class OpenApiWsDtoControllerApi
 * @extends {BaseAPI}
 */
export class OpenApiWsDtoControllerApi extends BaseAPI {
    /**
     * 
     * @param {AbstractActivatedSensors} [abstractActivatedSensors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public abstractActivatedSensors(abstractActivatedSensors?: AbstractActivatedSensors, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).abstractActivatedSensors(abstractActivatedSensors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbstractMonitorMessage} [abstractMonitorMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public abstractMonitorMessage(abstractMonitorMessage?: AbstractMonitorMessage, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).abstractMonitorMessage(abstractMonitorMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbstractStatusPayload} [abstractStatusPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public abstractStatusPayload(abstractStatusPayload?: AbstractStatusPayload, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).abstractStatusPayload(abstractStatusPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbstractStatusPayloadV0V1} [abstractStatusPayloadV0V1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public abstractStatusPayloadV0V1(abstractStatusPayloadV0V1?: AbstractStatusPayloadV0V1, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).abstractStatusPayloadV0V1(abstractStatusPayloadV0V1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonitorPacketDto} [monitorPacketDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public monitorPacketDto(monitorPacketDto?: MonitorPacketDto, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).monitorPacketDto(monitorPacketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatusPayloadV0} [statusPayloadV0] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public statusPayloadV0(statusPayloadV0?: StatusPayloadV0, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).statusPayloadV0(statusPayloadV0, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatusPayloadV1} [statusPayloadV1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public statusPayloadV1(statusPayloadV1?: StatusPayloadV1, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).statusPayloadV1(statusPayloadV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatusPayloadV2} [statusPayloadV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApiWsDtoControllerApi
     */
    public statusPayloadV2(statusPayloadV2?: StatusPayloadV2, options?: RawAxiosRequestConfig) {
        return OpenApiWsDtoControllerApiFp(this.configuration).statusPayloadV2(statusPayloadV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhoamiControllerApi - axios parameter creator
 * @export
 */
export const WhoamiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        principal: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhoamiControllerApi - functional programming interface
 * @export
 */
export const WhoamiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhoamiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async principal(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Principal200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.principal(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhoamiControllerApi.principal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhoamiControllerApi - factory interface
 * @export
 */
export const WhoamiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhoamiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        principal(options?: any): AxiosPromise<Principal200Response> {
            return localVarFp.principal(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhoamiControllerApi - object-oriented interface
 * @export
 * @class WhoamiControllerApi
 * @extends {BaseAPI}
 */
export class WhoamiControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhoamiControllerApi
     */
    public principal(options?: RawAxiosRequestConfig) {
        return WhoamiControllerApiFp(this.configuration).principal(options).then((request) => request(this.axios, this.basePath));
    }
}



