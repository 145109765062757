import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { COBRA_API } from "../api/config";
import { HANDHELD_SETTINGS_PAGE } from "../constants/pages";
import { setIsHandheldConnectionActive } from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";
import { useIsDeviceOnline } from "./useIsDeviceOnline";

export const useDeactivateOpenHandheldConnection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );
  const isOpenHandheldConnectionActive = useSelector(
    (state: RootState) => state.device.isHandheldConnectionActive
  );

  const isDeviceOnline = useIsDeviceOnline();

  useEffect(() => {
    if (!currentConnectedDeviceId || !location || !isDeviceOnline) return;
    if (
      location.pathname !== HANDHELD_SETTINGS_PAGE &&
      isOpenHandheldConnectionActive
    ) {
      (async () => {
        try {
          const handheldConnectionDto = (
            await COBRA_API.HandheldSettings.changeHandheldConnectionState(
              currentConnectedDeviceId,
              false
            )
          ).data;
          dispatch(setIsHandheldConnectionActive(!!handheldConnectionDto.active));
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [
    currentConnectedDeviceId,
    isOpenHandheldConnectionActive,
    isDeviceOnline,
    location,
  ]);
};
